<template>
  <div class="kt-grid kt-grid--ver kt-grid--root kt-page login-page">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin" id="kt_login">
      <div
          class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
        <div class="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
          <div class="kt-login__wrapper">
            <div class="kt-login__container">
              <div class="kt-login__body">
                <div class="kt-login__logo">
                  <div class="kt-header__brand-logo">
                    <h2 v-if="$route.query && $route.query.shop">Sign In</h2>
                    <h2 v-else>Logged Out</h2>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content">
          <div class="kt-login__section">
            <div class="kt-login__block">
              <h3 class="kt-login__title">Let`s start Now</h3>
              <div class="kt-login__desc">
                SweetCheckout - One Click Sell by SweetEcom
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {provider, requestStatus} from '../services'
import * as types from '../store/modules/auth/types'

export default {
  name: "login",
  data: () => ({
    shop: '',
    isLoading: false
  }),
  computed: {
    ...mapState({
      redirect_url: state => state.auth.redirect_url,
      status: state => state.auth.status,
    }),
    requestStatus: () => requestStatus
  },
  methods: {
    ...mapActions({
      login: `auth/${types.LOGIN}`,
    }),
    onLogin: function () {
      if (this.shop.length) {
        let shop_name = this.shop.indexOf('.myshopify.com') > -1 ? this.shop : this.shop + '.myshopify.com';
        this.login({data: {shop: shop_name}, provider: provider.shopify})
      } else {
        this.$toaster.warning('Please, input correct shop name')
      }
    }
  },
  watch: {
    redirect_url(newValue) {
      if (newValue) {
        window.location = newValue;
      }
    },
    status(newValue) {
      this.isLoading = newValue.login === requestStatus.loading
    }
  },
  created: function () {
    if (JSON.parse(localStorage.getItem('user'))) {
      localStorage.removeItem('user');
    }
    if (this.$route.query && this.$route.query.shop) {
      this.shop = this.$route.query.shop;
      this.onLogin();
    }
  }
}
</script>

<style scoped>
@import "../assets/style/login.min.css";
</style>